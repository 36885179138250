























































































import Vue from 'vue';
import tabs from '@/components/tabs.vue';
import QueueHandler from '@/components/QueueHandler.vue';
import apiBackend, { ICalculate } from '@/service/api-insurance';
import { CASE_STATUS } from '@/constant/constants';
import { copyObject, getNextRouteNameByKeyToPricePageHelper, getTabInfoHelper } from '@/helper/functions';
import popUpModal from '@/components/form/pop-up-modal.vue';
import { AuthResponse } from '@/model/auth';
import { getUser } from '@/helper/handle-auth';
import InsuranceTab from '@/model/tabs';
import PriceObj from '@/model/price';
import XMLStatusObj from '@/model/XMLStatusObj';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface LooseObject {
  [key: string]: any;
}

type DataType = {
  id: string | null;
  uuid: string | null;
  AMSAPISendStatus: XMLStatusObj | null;
  activeTab: InsuranceTab;
  productId: string;
  insuranceData: ICalculate | undefined;
  formData: any;
  spreadsheetData: any;
  basicData: any;
  riskFactorData: any;
  insuranceFormsData: LooseObject;
  insuranceFormsApiData: LooseObject;
  priceFormData: any;
  priceData: PriceObj;
  customerData: any;
  customerDetails: any;
  calculatedPrice: any;
  key: string;
  priceCardData: any;
  response: any;
  caseStatus: number;
  backUrl: any;
  price: number;
  isPricePage: boolean;
  priceApiData: any;
  investmentFile: string;
  signImage: string;
  pdfName: any;
  modalOpen: boolean;
  modalTitle: any;
  modalBody: any;
  customTabsInsuranceForms: any;
  processSavingData: boolean;
  dataUpdated: boolean;
};

const defaultAmsApiStatus = {
  tarif: null,
  variant1: null,
  variant2: null,
  variant3: null,
};

export default Vue.extend({
  name: 'index',
  props: [''],
  components: {
    tabs,
    popUpModal,
    QueueHandler,
  },
  data(): DataType {
    return {
      id: null,
      uuid: null,
      AMSAPISendStatus: copyObject(defaultAmsApiStatus),
      activeTab: {
        groupId: 'insuranceForms',
        tabId: 1,
        name: '',
        path: '',
      },
      productId: '',
      insuranceData: undefined,
      formData: null,
      priceFormData: null,
      spreadsheetData: null,
      basicData: null,
      riskFactorData: null,
      insuranceFormsData: {
      },
      insuranceFormsApiData: {
      },
      priceData: {
        tarif: {
          priceRequest: {
          },
          priceResponse: {
          },
          dataUpdated: false,
        },
        variant1: {
          priceRequest: {
          },
          priceResponse: {
          },
          dataUpdated: false,
        },
        variant2: {
          priceRequest: {
          },
          priceResponse: {
          },
          dataUpdated: false,
        },
        variant3: {
          priceRequest: {
          },
          priceResponse: {
          },
          dataUpdated: false,
        },
      },
      customerData: null,
      customerDetails: null,
      calculatedPrice: null,
      key: '',
      response: null,
      priceCardData: null,
      caseStatus: CASE_STATUS.DRAFT,
      backUrl: '',
      price: 0,
      isPricePage: false,
      priceApiData: undefined,
      investmentFile: '',
      signImage: '',
      pdfName: '',
      modalOpen: false,
      modalTitle: '',
      modalBody: '',
      customTabsInsuranceForms: {
      },
      processSavingData: false,
      dataUpdated: false,
    };
  },
  watch: {
    $route(to, from) {
      if (to.name.includes('price-card')) {
        this.isPricePage = true;
      } else {
        this.isPricePage = false;
      }
    },
  },
  methods: {
    changeDataForPriceRequest(dataUpdated: boolean) {
      console.log('changeDataForPriceRequest');
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.priceData) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.priceData.hasOwnProperty(key)) {
          const status = this.priceData[key as keyof PriceObj];
          if (status) {
            status.dataUpdated = dataUpdated;
          }
        }
      }
    },
    changeDataForPriceRequestForVariant(dataUpdated: boolean, variant: string) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.priceData.hasOwnProperty(variant)) {
        const status = this.priceData[variant as keyof PriceObj];
        if (status) {
          status.dataUpdated = dataUpdated;
        }
      }
    },
    insuranceDataChanged(dataUpdated: boolean) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.AMSAPISendStatus) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.AMSAPISendStatus.hasOwnProperty(key)) {
          const status = this.AMSAPISendStatus[key as keyof XMLStatusObj];
          if (status) {
            status.dataUpdated = dataUpdated;
          }
        }
      }
      //
      // this.dataUpdated = dataUpdated;
      // console.log(`dataUpdated ${dataUpdated}`);
    },
    addToQueue(functionName: string, variant = '', tab = ''): void {
      (this.$refs.queueHandler as any).enqueue(functionName, variant, tab);
    },
    getInsuranceFormsApiData() {
      apiBackend.getInsuranceFormsApiData().then((response: any) => {
        this.insuranceFormsApiData = response.data;
      }).catch((e) => {
        console.log(`Error - ${e}`);
      });
    },
    openModal(title: string, body: string) {
      this.modalTitle = title;
      this.modalBody = body;
      this.modalOpen = !this.modalOpen;
    },
    modalClose() {
      this.modalTitle = '';
      this.modalBody = '';
      this.modalOpen = false;
    },
    onFirstPageDataChange() {
      this.caseStatus = CASE_STATUS.DRAFT;
    },
    changeTab(activeTab: InsuranceTab) {
      this.activeTab = activeTab;
    },
    changeCustomTabsInsuranceForms(customTabsInsuranceForms: any) {
      this.customTabsInsuranceForms = customTabsInsuranceForms;
    },
    onManuallyPriceUpdate() {
      this.caseStatus = CASE_STATUS.OFFER_REQUEST_READY;
    },
    onRequestResponse(values: any) {
      this.response = values;
      this.id = values.id;
      this.uuid = values.uuid;
      this.formData = values.form_data;
      this.AMSAPISendStatus = values.AMSAPISendStatus || copyObject(defaultAmsApiStatus);
      this.insuranceData = values.form_data.insuranceData;
      this.priceApiData = values.form_data.priceApiData;
      this.investmentFile = values.investment_file;
      this.signImage = values.sign_image;
      this.$emit('onId', values.sig !== '' ? values.sig : values.id_with_year);
      this.$emit('getCustomerData', this.customerData);
      // this.activeTab = values.form_data.tabId || 1;
      this.processSavingData = false;
    },
    onPriceCardData(values: any, variantPrice: string) {
      let variant = 'tarif';
      if (variantPrice === 'SBTarif') {
        variant = 'tarif';
      } else if (variantPrice === 'SBVariante1') {
        variant = 'variant1';
      } else if (variantPrice === 'SBVariante2') {
        variant = 'variant2';
      } else if (variantPrice === 'SBVariante3') {
        variant = 'variant3';
      }
      this.priceData[variant].priceRequest = values;
      this.getPrice(values, variant);
    },
    onPriceCardRequest(values: any) {
      apiBackend.getPriceRequest(values).then((response: any) => {
        console.log(response.data);
      }).catch((e) => {
        // this.priceData.noPrice = true;
        console.log(`Error - ${e}`);
      });
    },
    getPrice(values: any, variantPrice: string) {
      apiBackend.calculate(values).then((response: any) => {
        this.priceData[variantPrice].priceResponse = response.data;
        this.priceData[variantPrice].dataUpdated = false;

        console.log('price response', response.data);
      }).catch((e) => {
        // this.priceData.noPrice = true;
        console.log(`Error - ${e}`);
      });
      if (
        this.caseStatus !== CASE_STATUS.SUGGESTION &&
        this.caseStatus !== CASE_STATUS.CONTRACT &&
        this.caseStatus !== CASE_STATUS.CONTRACT_NO_SIGNATURE &&
        this.caseStatus !== CASE_STATUS.OFFER_REQUEST_READY
      ) {
        // if (this.priceData.noPrice) {
        //   this.caseStatus = CASE_STATUS.OFFER_REQUEST;
        // } else {
        this.caseStatus = CASE_STATUS.OFFER;
        // }
      }
    },
    getPriceUseId(id: number, variantPrice: string) {
      apiBackend.getPrice(id, variantPrice).then((response: any) => {
        this.priceData = response.data;
        const { priceResponse } = response.data[variantPrice];
        this.priceData[variantPrice].dataUpdated = false;

        // Check if there is a message in priceResponse.Message
        if (priceResponse.Message !== undefined && priceResponse.Message !== null) {
          this.openModal('error', priceResponse.Message);
        }

        // Check if there are messages in priceResponse.Result.Messages
        if (priceResponse.Result.Messages !== undefined && priceResponse.Result.Messages !== null) {
          const messages = priceResponse.Result.Messages;

          // If there is exactly one message and it is "Achtung: Mindestprämie 300€.", do not show the error
          if (!(messages.length === 1 && messages[0] === 'Achtung: Mindestprämie300€.')) {
            let messagesStr = '';
            messages.forEach((element: any) => {
              messagesStr = `${messagesStr + element} `;
            });
            this.openModal('errors', messagesStr);
          }
        }
      }).catch((e) => {
        console.log(`Error1 - ${e}`);
      });
    },
    onRequestProposalClick() {
      if (this.caseStatus !== CASE_STATUS.SUGGESTION) {
        this.caseStatus = CASE_STATUS.SUGGESTION;
        this.saveAsDraft();
      }
    },
    onOfferRequest() {
      this.caseStatus = CASE_STATUS.OFFER_REQUEST;
      // this.AMSAPISendStatus = 'loading';
      // this.saveAsDraft();
    },
    onDigitalSign() {
      this.caseStatus = CASE_STATUS.CONTRACT;
      this.saveAsDraft();
    },
    contractNoSignature() {
      this.caseStatus = CASE_STATUS.CONTRACT_NO_SIGNATURE;
      this.saveAsDraft();
    },
    onCalculatedPrice(values: any) {
      this.calculatedPrice = values;
    },
    onCustomerData(values: any) {
      this.customerData = values;
      this.saveAsDraft();
    },
    onPriceFormData(values: any, key: any = null, groupId = '', tabId = 0) {
      if (!this.priceFormData) {
        this.priceFormData = {
        };
      }

      if (key) {
        this.priceFormData[key] = values;
      } else {
        this.priceFormData = values;
      }

      this.saveAsDraft();

      if (groupId && tabId) {
        this.setActiveTab(groupId, tabId);
        this.$store.commit('tabsModule/setTabsItemsByInsuranceKey', this.key);

        const tabsInsurance = this.$store.getters['tabsModule/getTabsHelper'];
        this.activeTab = getTabInfoHelper(this.activeTab, tabsInsurance);
      }
    },
    onFormData(values: any) {
      this.formData = values;
      this.saveAsDraft();
    },
    onCustomerGeneral(values: any) {
      this.customerDetails = values;
      this.setActiveTab('police', 1);

      this.setTabInfoHelper();
      this.saveAsDraft();
    },
    onAddPdf(values: any) {
      this.pdfName = values;
    },
    caseStatusChange(values: any) {
      this.caseStatus = values;
    },
    onKeyChange(key: string) {
      this.key = key;
      this.backUrl = this.getRouteByKey(key);
    },
    getRouteByKey(key: any) {
      let name = '';
      switch (key) {
        case 'INDIVIDUAL_INSURANCE':
          name = 'individual-insurance';
          break;
        default:
          break;
      }
      return name;
    },
    onSpreadsheetDataChange(spreadsheetData: any, key: any = null, nextPrice = false, changeTabId = true) {
      this.spreadsheetData = spreadsheetData;
      this.saveAsDraft();

      if (key) {
        this.key = key;
        this.backUrl = this.getRouteByKey(key);
      }

      if (nextPrice) {
        this.setActiveTab('priceForms', 1);
      } else if (changeTabId) {
        this.activeTab.tabId += 1;
      }

      this.setTabInfoHelper();

      if (this.caseStatus === 0 || this.caseStatus === 1 || this.caseStatus === 11) {
        this.saveAsDraft();
      }
    },
    onBasicDataChange(basicData: any, key: any = null, tabId: number) {
      this.basicData = basicData;
      if (key) {
        this.key = key;
        this.backUrl = this.getRouteByKey(key);
      }
      if (tabId) {
        this.activeTab.tabId = tabId;
      } else {
        this.activeTab.tabId += 1;
      }

      this.setTabInfoHelper();
      if (this.caseStatus === 0 || this.caseStatus === 1 || this.caseStatus === 11) {
        this.saveAsDraft();
      }
    },
    onElektronikDataChange(elektronikTabData: any, tabKey: string, tabId: number) {
      /* eslint-disable @typescript-eslint/no-explicit-any */
      this.basicData[tabKey] = elektronikTabData;
      if (tabId) {
        this.activeTab.tabId = tabId;
      } else {
        this.setActiveTab('priceForms', 1);
      }
      this.setTabInfoHelper();

      if (this.caseStatus === 0 || this.caseStatus === 1 || this.caseStatus === 11) {
        this.saveAsDraft();
      }
    },
    onInsuranceFormsTabDataChange(insuranceFormTabData: any, tabKey: string, tabId: number) {
      /* eslint-disable @typescript-eslint/no-explicit-any */
      this.insuranceFormsData[tabKey] = insuranceFormTabData;
      if (tabId) {
        this.activeTab.tabId = tabId;
      } else {
        this.setActiveTab('priceForms', 1);
      }

      this.setTabInfoHelper();

      if (this.caseStatus === 0 || this.caseStatus === 1 || this.caseStatus === 11) {
        this.saveAsDraft();
      }
    },
    onRiskFactorDataChange(values: any, goNextPage = true) {
      this.riskFactorData = values;
      if (goNextPage) {
        this.setActiveTab('priceForms', 1);
        this.setTabInfoHelper();
      }
      this.saveAsDraft();
    },
    goBackTab(routeName: string, groupId: string, tabId: number) {
      // this.activeTab -= 1;
      if (groupId && tabId) {
        this.setActiveTab(groupId, tabId);
        this.setTabInfoHelper();
      }

      this.$router.push({
        name: routeName,
        params: {
          id: this.$route.params.id,
        },
      });
    },
    goToTab(groupId: string, tabId: number) {
      if (groupId && tabId) {
        this.setActiveTab(groupId, tabId);
        this.setTabInfoHelper();
      }
    },
    setActiveTab(groupId: string, tabId: number) {
      this.activeTab.tabId = tabId;
      this.activeTab.groupId = groupId;
    },
    setTabInfoHelper() {
      const tabsInsurance = this.$store.getters['tabsModule/getTabsHelper'];
      this.activeTab = getTabInfoHelper(this.activeTab, tabsInsurance);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onDataChanged(formData: any, values: any, key: any = null, nextPach: any = null) {
      this.priceApiData = values;
      this.formData = {
        ...(this.formData as any),
        insuranceData: formData,
        priceApiData: this.priceApiData,
      };
      if (key) {
        this.key = key;
        this.backUrl = this.getRouteByKey(key);
      }
      let routeName = 'home';
      if (nextPach) {
        routeName = nextPach;
      } else {
        routeName = getNextRouteNameByKeyToPricePageHelper(this.key);
      }
      // this.activeTab += 1;
      this.$router.push({
        name: routeName,
        params: {
          id: this.$route.params.id,
        },
      });
      if (this.caseStatus === 0 || this.caseStatus === 1) {
        this.saveAsDraft();
      }
    },
    getDraftInsurance(id: string) {
      apiBackend.fetchSingle(id).then((response) => {
        this.setValues(response.data.data);
      });
    },
    saveAsDraft() {
      this.processSavingData = true;
      /* eslint-disable @typescript-eslint/camelcase */
      const request = {
        id: this.id,
        uuid: this.uuid,
        insurance_type: this.key,
        form_data: {
          ...(this.formData as any),
          customerData: {
            user: this.customerData,
            general: this.customerDetails,
          },
          basicData: this.basicData,
          insuranceFormsData: this.insuranceFormsData,
          spreadsheetData: this.spreadsheetData,
          riskFactorData: this.riskFactorData,
          priceFormData: this.priceFormData,
          activeTab: this.activeTab,
          dataUpdated: this.dataUpdated,
        },
        price: this.price,
        price_data: this.priceData,
        status: this.caseStatus,
        AMSAPISendStatus: this.AMSAPISendStatus,
        is_draft: true,
      };
      // this.$emit('getCustomerData', this.customerData);
      apiBackend.insurance(request).then((response) => {
        if (response.data.status !== undefined) {
          this.caseStatus = Number(response.data.status);
        }
        if (this.caseStatus === CASE_STATUS.SUGGESTION || this.caseStatus === CASE_STATUS.CONTRACT || this.caseStatus === CASE_STATUS.CONTRACT_NO_SIGNATURE) {
          this.$emit('request-response', response.data.data);
        }
        this.onRequestResponse(response.data.data);

        if (response.data.data.sig !== null && response.data.data.sig !== '') {
          this.$store.commit('sidModule/setSID', response.data.data.sig);
          this.$store.dispatch('sidModule/fetchSIDFromAPI', response.data.data.sig);
        }
        this.dataUpdated = false;
        // this.$router.push('/transport-insurance/proposal-policy');
      });
    },

    setValues(data: any) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      const { form_data, price_data, insurance_type, status, id, uuid, investment_file, sign_image, AMSAPISendStatus } = data;
      this.id = id;
      this.uuid = uuid;
      this.AMSAPISendStatus = AMSAPISendStatus || copyObject(defaultAmsApiStatus);
      const { price } = form_data.price_form_data || 0;
      // eslint-disable-next-line @typescript-eslint/camelcase
      const { insuranceData, customerData, priceFormData, basicData, spreadsheetData, riskFactorData } = form_data;

      this.insuranceData = insuranceData;
      this.customerData = customerData ? customerData.user : null;
      this.customerDetails = customerData ? customerData.general : null;
      this.priceFormData = priceFormData;
      this.basicData = basicData;
      this.spreadsheetData = spreadsheetData;
      this.riskFactorData = riskFactorData;
      this.priceData = price_data;
      this.investmentFile = investment_file;
      this.signImage = sign_image;
      // if (price !== undefined) {
      //   // eslint-disable-next-line @typescript-eslint/camelcase
      //   this.priceCardData = {
      //     price_data,
      //     price,
      //   };
      // }
      // this.priceCardData = insuranceData.price_form_data;
      this.price = price;
      // eslint-disable-next-line @typescript-eslint/camelcase
      this.key = insurance_type;
      this.caseStatus = Number(status);
      this.changeTab(form_data.activeTab);
    },
  },
  computed: {
    roles() {
      const user: AuthResponse | null = getUser();

      return user?.roles || [];
    },
    isAPIObserver(): boolean {
      return this.roles.indexOf('APIObserver') > -1;
    },
    isPCUser(): boolean {
      return this.$store.getters['authModule/isPCUser'];
    },
    title(): any {
      return this.$route.matched[0].meta.title;
    },
    getInsuranceData(): any {
      return this.formData;
    },
  },
  mounted() {
    if (this.$route.params.id) {
      apiBackend.fetchSingle(this.$route.params.id).then((response) => {
        this.setValues(response.data.data);
        this.onRequestResponse(response.data.data);
      });
    }
    this.getInsuranceFormsApiData();
    // else {
    //   this.getDraftInsurance();
    // }
  },
  beforeDestroy() {
    if (this.isPCUser && this.id) {
      apiBackend.deletePCUInsurance(this.id);
    }
  },
});
